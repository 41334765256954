import type { PropsWithChildren } from "react";
import type { DraggableProvided } from "react-beautiful-dnd";
import clsx from "clsx";

type Props = PropsWithChildren<{
  id: string;
  provided: DraggableProvided;
  danger?: boolean | null;
  isDragging?: boolean;
}>;

export default function DraggableCard({
  children,
  id,
  isDragging,
  danger,
  provided
}: Props) {
  return (
    <div
      ref={provided.innerRef}
      data-id={id}
      className={clsx(
        "mb-4 space-y-1 rounded-md border border-gray-300 px-3 py-2",
        isDragging && "opacity-75",
        danger ? "bg-red-200" : "bg-white"
      )}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      {children}
    </div>
  );
}
