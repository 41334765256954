import type { DropResult } from "react-beautiful-dnd";
import type { ComboBoxOption } from "~/components/combobox";
import type {
  DeliverableFieldsFragment,
  KanbanCardFieldsFragment,
  ContentTopicListFieldsFragment
} from "~/types/api";
import { useFetcher, useSearchParams } from "@remix-run/react";
import clsx from "clsx";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { flushSync } from "react-dom";
import Alert from "~/components/alert";
import Button from "~/components/button";
import Column from "~/components/kanban/column";
import { useLocationPusher } from "~/components/link";
import { useOptionalProject } from "~/contexts";
import { formatDate } from "~/utils/dates";

const defaultColumns = [
  "Backlog",
  "Could Do",
  "Should Do",
  "Urgent",
  "Next",
  "Scheduled",
  "Today",
  "Done"
];

interface Props {
  cards: (KanbanCardFieldsFragment | ContentTopicListFieldsFragment)[];
  onAdd?: (column: string) => void;
  deliverable?: DeliverableFieldsFragment;
  panel?: boolean;
  showParent?: boolean;
  className?: string;
  filter?: string;
  groupBy?: "user";
  hideUser?: boolean;
  openBacklog?: () => void;
  showFullBacklog?: boolean;
  showHours?: boolean;
  columns?: string[];
  label?: string;
  backlogColumn?: string;
  columnDescriptions?: ComboBoxOption[];
}

export default function Board({
  cards: sourceCards,
  deliverable,
  panel,
  showParent,
  onAdd,
  className,
  filter,
  hideUser,
  openBacklog,
  showFullBacklog,
  showHours = true,
  label = "Cards",
  columnDescriptions,
  columns = defaultColumns
}: Props) {
  const initialRender = useRef(true);
  const fetcher = useFetcher<unknown>();
  const project = useOptionalProject();
  const [params] = useSearchParams();
  const [cards, setCards] = useState(
    _.sortBy(sourceCards, (c) =>
      c.__typename === "KanbanCard" ? c.rank : c.statusRank
    )
  );
  const push = useLocationPusher();

  useEffect(() => {
    if (!initialRender.current) {
      setCards(
        _.sortBy(sourceCards, (c) =>
          c.__typename === "KanbanCard" ? c.rank : c.statusRank
        )
      );
    }
    initialRender.current = false;
  }, [sourceCards]);

  const filtered = filter
    ? cards.filter((card) =>
        card.__typename === "KanbanCard"
          ? `${card.contentTopic?.title} ${card.project?.number || ""} ${
              card.project?.name || ""
            } ${card.deliverable?.description || ""} ${
              card.feature?.title || ""
            } ${card.title || ""}`
              .toLocaleLowerCase()
              .includes(filter.toLocaleLowerCase())
          : card.title.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      )
    : cards;

  const onDragEnd = (result: DropResult) => {
    if (
      result.destination &&
      (result.destination.droppableId !== result.source.droppableId ||
        result.destination.index !== result.source.index)
    ) {
      const column = result.destination?.droppableId;
      const card = cards.find((c) => c.id === result.draggableId);

      if (card) {
        const columnCards = cards.filter(
          (c) =>
            (c.__typename === "KanbanCard"
              ? c.column === column
              : c.status === column) && c.id !== card.id
        );

        let nextColumnCards = [...columnCards];
        const next =
          card.__typename === "KanbanCard"
            ? {
                ...card,
                column: result.destination.droppableId,
                dateCompleted: formatDate(new Date(), { format: "YYYY-MM-DD" })
              }
            : { ...card, status: result.destination.droppableId };

        nextColumnCards.splice(result.destination.index, 0, next);
        nextColumnCards = nextColumnCards.map((card, i) => ({
          ...card,
          [card.__typename === "ContentTopic" ? "statusRank" : "rank"]: i
        }));
        flushSync(() =>
          setCards([
            ...cards.filter(
              (c) =>
                (c.__typename === "KanbanCard"
                  ? c.column !== column
                  : c.status !== column) && c.id !== card.id
            ),
            ...nextColumnCards
          ])
        );
        if (card.__typename === "KanbanCard") {
          fetcher.submit(
            {
              id: card.id,
              column,
              position:
                result.destination.index === columnCards.length ? "last" : "",
              rank:
                result.destination.index === columnCards.length
                  ? ""
                  : columnCards[result.destination.index].rank?.toString()
            },
            {
              action: "/resources/kanban/sort",
              method: "post"
            }
          );
        } else if (card.__typename === "ContentTopic") {
          const fd = new FormData();
          fd.set("id", card.id);
          fd.set("status", column);
          if (result.destination.index === columnCards.length) {
            fd.set("statusPosition", "last");
          } else {
            fd.set(
              "statusRank",
              (
                columnCards[
                  result.destination.index
                ] as ContentTopicListFieldsFragment
              ).statusRank.toString()
            );
          }
          fetcher.submit(fd, {
            action: "/resources/content/sort",
            method: "post"
          });
        }
      }
    }
  };

  const showOldLink =
    params.get("completed-cards") !== "true" && project?.hasKanbanCards;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {filtered.length ? (
        <div
          className={clsx(
            "flex overflow-x-scroll bg-gray-100",
            panel ? "px-4" : "rounded-lg border border-gray-200 px-2",
            className
          )}
        >
          {columns.map((column) => (
            <Column
              label={label}
              showHours={showHours}
              showOld={project ? "toggle" : "redirect"}
              showProject={showParent}
              hideUser={hideUser}
              key={column}
              deliverable={deliverable}
              onAdd={onAdd}
              panel={panel}
              column={column}
              columnDescriptions={columnDescriptions}
              cards={filtered.filter(
                (c) =>
                  (c.__typename === "KanbanCard" ? c.column : c.status) ===
                  column
              )}
              openBacklog={openBacklog}
              showFullBacklog={showFullBacklog}
            />
          ))}
        </div>
      ) : (
        <Alert mode="warning" className="flex items-center justify-between">
          No {filter ? "Matching " : showOldLink ? "Current " : ""}
          Kanban Cards!
          {showOldLink && project.hasKanbanCards && (
            <Button
              className="-my-1"
              small
              onClick={() =>
                push({ "completed-cards": true }, { replace: true })
              }
            >
              Show Completed Cards
            </Button>
          )}
        </Alert>
      )}
    </DragDropContext>
  );
}
