import type { DraggableProvided } from "react-beautiful-dnd";
import type {
  DeliverableFieldsFragment,
  KanbanCardFieldsFragment
} from "~/types/api";
import clsx from "clsx";
import _ from "lodash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  IconAttachment,
  IconCheckedSquare,
  IconCommentsLight
} from "~/components/icons";
import DraggableCard from "~/components/kanban/draggable-card";
import CardForm from "~/components/kanban/form";
import KanbanTitle from "~/components/kanban/title";
import Link from "~/components/link";
import { canAccessProject } from "~/components/projects/team";
import { useCurrentUser } from "~/utils/auth";
import { formatDate, isPastDue } from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";
import { slateIsBlank } from "~/utils/text";

interface Props {
  card: KanbanCardFieldsFragment;
  index: number;
  deliverable?: DeliverableFieldsFragment;
  noDeliverable: boolean;
  noProject: boolean;
  provided: DraggableProvided;
  isDragging: boolean;
  hideUser?: boolean;
}

export default function Card({
  card,
  noDeliverable,
  provided,
  isDragging,
  noProject,
  hideUser
}: Props) {
  const [editing, setEditing] = useState(false);
  const currentUser = useCurrentUser();
  const canView = canAccessProject(currentUser, card);
  const [params] = useSearchParams();

  const project = () => {
    if (noProject && (card.deliverable || card.title)) {
      return null;
    } else if (card.training) {
      return (
        <div>
          <Link
            className="hover:no-underline"
            to={`/training/${card.training.id}`}
          >
            <strong>Training</strong>: {card.training.name}
          </Link>
        </div>
      );
    } else if (card.feature) {
      return (
        <div>
          <Link className="hover:no-underline" to={() => setEditing(true)}>
            <strong>Bug/Feature</strong>: {card.feature.category}:{" "}
            {card.feature.title}
          </Link>
        </div>
      );
    } else if (card.contentTopic) {
      return (
        <div className="truncate font-bold">
          <Link
            className="hover:no-underline"
            to={`/content/${card.contentTopic.id}`}
          >
            {card.contentTopic.title}
          </Link>
        </div>
      );
    } else if (card.project) {
      return (
        <div className="truncate font-bold">
          <Link
            className="hover:no-underline"
            to={`/projects/${card.project.number}`}
          >
            {card.project.number} {card.project.name}
          </Link>
        </div>
      );
    }
    return null;
  };

  const pastDue = card.column !== "Done" && isPastDue(card.dueDate);

  const completedTasks = card.tasks.filter((c) => c.completed);
  let tasksClassName = "";
  if (completedTasks.length === card.tasks.length) {
    tasksClassName = "text-success";
  } else if (_.some(card.tasks, (t) => !t.completed && isPastDue(t.itemDate))) {
    tasksClassName = "text-danger";
  }

  return (
    <DraggableCard
      provided={provided}
      id={card.id}
      isDragging={isDragging}
      danger={card.blocked}
    >
      {project()}
      <KanbanTitle
        card={card}
        format="card"
        canView={canView}
        hideDeliverable={noDeliverable}
        onEdit={() => setEditing(true)}
      />
      {card.dueDate ||
      !slateIsBlank(card.notesSlate) ||
      card.hours ||
      !!card.uploads.length ||
      !!card.tasks.length ||
      (card.user && !hideUser) ? (
        <div className="flex flex-wrap justify-end space-x-2">
          {card.dueDate && (
            <div
              className={clsx("flex-1", pastDue && "font-bold text-red-700")}
              title="Due Date"
            >
              {formatDate(card.dueDate)}
            </div>
          )}

          {card.hours && (
            <div className="text-gray-600" title="Hours">
              {formatNumber(card.hours, { zeroes: true })}
            </div>
          )}

          {!slateIsBlank(card.notesSlate) && (
            <div>
              <IconCommentsLight
                title="Additional notes..."
                titleId="icon-comments"
                className="text-gray-500"
              />
            </div>
          )}
          {!!card.uploads.length && (
            <div>
              <IconAttachment className="text-gray-500" />
            </div>
          )}
          {!!card.tasks.length && (
            <div
              className={clsx("text-gray-500", tasksClassName)}
              title="Actions Completed"
            >
              <IconCheckedSquare /> {completedTasks.length}/{card.tasks.length}
            </div>
          )}
          {card.user && !hideUser && (
            <div
              className="text-gray-500"
              title={`Assigned To: ${card.user.fullname}`}
            >
              {card.user.fullname}
            </div>
          )}
        </div>
      ) : null}
      {params.get("debug") === "true" && card.rank}
      {editing && <CardForm id={card.id} onClose={() => setEditing(false)} />}
    </DraggableCard>
  );
}
